import Swal from 'sweetalert2';
import { useEffect } from 'react';

const useNotification = () => {
  useEffect(() => {
    return () => {
      Swal.close(); // Close any open notifications when the component unmounts
    };
  }, []);

  const showNotificationWithMessage = (message, type) => {
    Swal.fire({
      title: message,
      icon: type === 'success' ? 'success' : 'error',
      timer: 3000,
      showConfirmButton: false,
    });
  };

  return { showNotificationWithMessage };
};

export default useNotification;
