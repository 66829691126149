import React, { createContext, useContext, useState } from 'react';
import { Spin } from 'antd';
import styles from './loader.module.css';

// Create a context for the global loader
const GlobalLoaderContext = createContext();

// Create a Provider component to wrap your app
export const GlobalLoaderProvider = ({ children }) => {

    const [loading, setLoading] = useState(false);
    const [blurBackground, setBlurBackground] = useState(false); // Add this state

    // console.log("loading from GlobalLoaderContext.js", loading);

    // Show the loader
    const showLoader = () => {
        // console.log("here>>>>>>>>>>>>>>>>>")
        setLoading(true);
        setBlurBackground(true); // Show blur background
    };

    // Hide the loader
    const hideLoader = () => {
        setLoading(false);
        setBlurBackground(false); // Hide blur background
    };

    return (
        <GlobalLoaderContext.Provider value={{ loading, showLoader, hideLoader }}>
            {children}
            {loading && (
                <div className={`${styles.loaderContainer} ${styles.blur}`}>
                    <Spin size='large' />
                </div>
            )}
        </GlobalLoaderContext.Provider>
    );
};

// Custom hook to access the global loader context
export const useGlobalLoader = () => useContext(GlobalLoaderContext);
